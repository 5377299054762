import React from 'react';
import {Link} from 'gatsby'

import ServiceIcon7 from '../../assets/images/services/service-icon7.png'
import ServiceShape4 from '../../assets/images/services/service-shape4.png'

const Services = () => {
    return (
        <section id="nav-3" className="services-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Наши Партнеры</h2>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={ServiceIcon7} alt="Service Icon" />
                            </div>
                            <h3>
                                <Link to="/">
                                </Link>
                            </h3>
                            

                            {/* <Link to="/" className="learn-more-btn">
                                <i className="flaticon-right"></i> Learn More
                            </Link> */}
                            {/* <div className="shape">
                                <img src={ServiceShape4} alt="Service Shape" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;